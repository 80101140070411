import { VersionCheck } from '@/common/version-check';
import { Title } from '@/design-system/v3/title';
import {
  getProfilePath,
  INBOX_PATH,
  LANGUAGES_PATH,
  PROFILE_PATH,
  QRCODE_PATH,
  RESERVATIONS_PATH,
  SHOP_PATH,
  SHOP_WEBSHOP_PATH,
} from '@/web/routes';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';
import { Translate } from 'phosphor-react';
import { IconWrapper } from '@/common/components/icon-wrapper';
import { ProfileAvatar } from '@/web/components/profile-avatar';
import HomeIcon from '@/common/components/icon/icons/v2/home.svg?react';
import QrCodeIcon from '@/common/components/icon/icons/v2/qrcode-alt.svg?react';
import NfcIcon from '@/common/components/icon/icons/v2/nfc-small.svg?react';
import InboxIcon from '@/common/components/icon/icons/v2/inbox.svg?react';
import StoreIcon from '@/common/components/icon/icons/v2/store.svg?react';
import { useBusiness } from '@/web/hooks/use-business';
import { useEmbed } from '@/common/hooks/use-embed';
import { CheckinUsageType } from '@/web/types';
import { useBusinessMemberships } from '@/web/hooks/use-business-memberships';

export const PageWrapper = ({
  content,
  contentHeightOffset = 0,
  subHeader,
  header,
  footer,
  aboveFooter,
  className,
  contentClasses,
  disableFooter = false,
  footerClasses,
  withLocaleSwitcher = false,
  embedHeader = false,
  embedFooter = false,
  headerType = 'default',
}: {
  content: ReactNode;
  headerType?: 'default' | 'transparent';
  header?: ReactNode;
  subHeader?: ReactNode;
  footer?: ReactNode;
  aboveFooter?: ReactNode;
  disableFooter?: boolean;
  className?: string;
  contentClasses?: string;
  contentHeightOffset?: number;
  footerClasses?: string;
  withLocaleSwitcher?: boolean;
  headerBorder?: boolean;
  embedHeader?: boolean;
  embedFooter?: boolean;
}) => {
  const embed = useEmbed();
  const isFooterDisabled = disableFooter || (embed && !embedFooter);

  const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    const onSetContentHeight = () => {
      if (contentEl) {
        const height = Number(contentEl?.offsetHeight);
        document.documentElement.style.setProperty('--content-height', `${height - contentHeightOffset}px`);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      onSetContentHeight();
    });

    onSetContentHeight();

    window.addEventListener('resize', onSetContentHeight);

    if (contentEl) {
      resizeObserver.observe(contentEl);
    }

    return () => {
      window.removeEventListener('resize', onSetContentHeight);

      if (contentEl) {
        resizeObserver.unobserve(contentEl);
      }
    };
  }, [contentEl, contentHeightOffset]);

  const containerStyle = useMemo(() => {
    if (!window.safeAreaInsets) {
      return {};
    }

    return {
      paddingTop: window.safeAreaInsets.top,
      paddingBottom: isFooterDisabled ? window.safeAreaInsets.bottom : 0,
    };
  }, [isFooterDisabled]);

  return (
    <>
      <motion.div>
        <div
          className={twMerge(
            'flex flex-col h-screenHeight bg-white text-typo-primary contrast:text-contrast-typo-primary',
            className,
          )}
          style={containerStyle}
        >
          <VersionCheck />
          {withLocaleSwitcher && (
            <div
              className="absolute right-4 z-50 flex space-2 items-center"
              style={{
                top: (window.safeAreaInsets?.top ?? 0) + 16,
              }}
            >
              <NavLink to={getProfilePath(LANGUAGES_PATH)}>
                <Translate size={18} />
              </NavLink>
              <ChevronDownIcon
                className="h-5 w-5 text-typo-secondary contrast:text-contrast-typo-secondary"
                aria-hidden="true"
              />
            </div>
          )}
          {(!embed || embedHeader) && (header || subHeader) ? (
            <div
              className={clsx('flex flex-col flex-shrink-0 z-20', {
                'border-b border-b-borders-secondary contrast:border-b-contrast-borders-secondary':
                  headerType === 'default',
                'bg-transparent': headerType === 'transparent',
              })}
            >
              {header}
              {subHeader}
            </div>
          ) : null}
          <motion.div
            id="pull-to-refresh"
            className={twMerge(
              'flex flex-col flex-grow px-4 overflow-y-auto relative md:max-w-[768px] md:w-full md:mx-auto',
              contentClasses,
            )}
            ref={setContentEl}
          >
            {content}
          </motion.div>
          {isFooterDisabled ? (
            <div className="relative">{aboveFooter}</div>
          ) : footer ? (
            <div
              className={twMerge('mb-4 w-full md:max-w-[768px] md:mx-auto md:px-0', footerClasses)}
              style={{
                paddingBottom: window.safeAreaInsets?.bottom,
              }}
            >
              {footer}
            </div>
          ) : (
            <div className={twMerge('relative', footerClasses)}>
              {aboveFooter}
              <PageFooter />
            </div>
          )}
        </div>
      </motion.div>
    </>
  );
};

const PageFooter = () => {
  const location = useLocation();
  const { businessFeatures, businessCTypes, businessSettings } = useBusiness();
  const { hasPurchasableMemberships } = useBusinessMemberships();

  const navMenuRoutes = useMemo(() => {
    const items: {
      path: string;
      matchWith?: string;
      icon: JSX.Element;
    }[] = [
      {
        path: '/',
        matchWith: RESERVATIONS_PATH,
        icon: <IconWrapper icon={HomeIcon} className="w-5" />,
      },
    ];

    const hasWebshop = businessFeatures?.includes('Webshop');

    if (hasWebshop || hasPurchasableMemberships) {
      items.push({
        path: hasPurchasableMemberships ? SHOP_PATH : `${SHOP_PATH}/${SHOP_WEBSHOP_PATH}`,
        icon: <IconWrapper icon={StoreIcon} className="w-5" />,
      });
    }

    if (businessFeatures?.includes('Checkin')) {
      items.push({
        path: QRCODE_PATH,
        icon: (
          <IconWrapper
            icon={businessSettings?.checkinUsageType === CheckinUsageType.NFC_ONLY ? NfcIcon : QrCodeIcon}
            className="w-5"
          />
        ),
      });
    }

    if (businessFeatures?.includes('Notification')) {
      items.push({
        path: INBOX_PATH,
        icon: <IconWrapper icon={InboxIcon} className="w-5" />,
      });
    }

    items.push({
      path: PROFILE_PATH,
      icon: <ProfileAvatar />,
    });

    return items;
  }, [businessCTypes, businessFeatures, businessSettings]);

  const containerStyle = useMemo(() => {
    if (!window.safeAreaInsets) {
      return {};
    }

    return {
      paddingBottom: window.safeAreaInsets.bottom,
    };
  }, []);

  return (
    <div className="flex bg-white" style={containerStyle}>
      {navMenuRoutes.map((route) => {
        const isSelected =
          route.path.length > 1
            ? location.pathname.startsWith(route.path)
            : route.path === location.pathname || location.pathname === route.matchWith;
        const navItemClasses = clsx(
          'flex-1 h-14 flex items-center justify-center',
          isSelected
            ? 'text-typo-primary contrast:text-contrast-typo-primary'
            : 'text-typo-secondary contrast:text-contrast-typo-secondary opacity-50',
        );
        return (
          <NavLink key={route.path} className={navItemClasses} to={route.path}>
            {route.icon}
          </NavLink>
        );
      })}
    </div>
  );
};

export const PageHeader = ({
  title,
  titlePosition = 'center',
  leftAction,
  rightAction,
}: {
  title?: ReactNode;
  titlePosition?: 'center' | 'left' | 'right';
  leftAction?: ReactNode;
  rightAction?: ReactNode;
}) => {
  return (
    <div className="flex items-center justify-center p-4 h-[48px]">
      <div className="w-6">{leftAction}</div>
      <div
        className={clsx('flex flex-1 grow', {
          'justify-center': titlePosition === 'center',
          'justify-start ms-1.5': titlePosition === 'left',
          'justify-end me-1.5': titlePosition === 'right',
        })}
      >
        <span className="font-neutral leading-none text-base">{title}</span>
      </div>
      <div className="w-6 flex justify-end text-right">{rightAction}</div>
    </div>
  );
};
