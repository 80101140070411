import { matchPath, useLocation } from 'react-router-dom';
import {
  BRING_A_FRIEND_INVITATION_PATH,
  FORGOT_PASSWORD_PATH,
  GUEST_ACTIVATION_PATH,
  INVITATION_PATH,
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
} from '@/auth/routes';
import {
  GUEST_MANAGE_BOOKING_PATH,
  LANGUAGES_PATH,
  ONBOARDING_COMPLETE_PATH,
  PAYMENT_COMPLETE_PATH,
} from '@/web/routes';

const pagesWithDesktopScreen = [
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  LANGUAGES_PATH,
  FORGOT_PASSWORD_PATH,
  INVITATION_PATH,
  `${INVITATION_PATH}/${BRING_A_FRIEND_INVITATION_PATH}`,
  GUEST_ACTIVATION_PATH,
  PAYMENT_COMPLETE_PATH,
  ONBOARDING_COMPLETE_PATH,
  `${ONBOARDING_COMPLETE_PATH}/:id`,
  GUEST_MANAGE_BOOKING_PATH,
];

export const usePageContext = (): {
  isOnboarding: boolean;
  isOnDesktopPage: boolean;
} => {
  const location = useLocation();
  const isOnboarding = location.pathname.includes('onboarding');

  const isOnDesktopPage = pagesWithDesktopScreen.some((path) => {
    return matchPath(path, location.pathname);
  });

  return {
    isOnboarding,
    isOnDesktopPage: isOnDesktopPage || isOnboarding,
  };
};
