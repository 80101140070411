export enum GuestMemberStatus {
  /**
   * The guest is an active member of the course.
   */
  ACTIVE = 'ACTIVE',

  /**
   * The guest has attended the course.
   */
  ATTENDED = 'ATTENDED',

  /**
   * The guest has signed-up but didn't show up for the course.
   */
  NO_SHOW = 'NO_SHOW',

  /**
   * The spot for the guest has been reserved.
   */
  RESERVED = 'RESERVED',

  /**
   * The guest is waiting to be accepted into the course.
   */
  WAITING = 'WAITING',

  /**
   * The guest has been cancelled.
   */
  CANCELLED = 'CANCELLED',

  /**
   * The guest has been cancelled too late.
   */
  LATE_CANCELLED = 'LATE_CANCELLED',
}
