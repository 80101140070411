import { downloadPicture } from '@/auth/endpoints';
import { LAST_SELECTED_BUSINESS_UUID, MENU_COLLAPSED_STORE, REPORT_MENU_COLLAPSED_STORE } from '@/common/constants';
import { getFromStorage, setInStorage } from '@/common/utils';
import { atom, selector } from 'recoil';
import { Business, BusinessWithUser, CourseType, Location, SelectOption } from '../web/types';
import {
  bookingFlowDate,
  bookingFlowLocation,
  bookingFlowRoom,
  bookingFlowType,
} from '@/common/components/booking-flow/atoms';
import { categoriesFilterState } from '@/web/views/shop/atoms';

export const selectedBusinessUuidState = atom<null | string>({
  key: 'selectedBusinessUuidState',
  default: null,
  effects: [
    ({ onSet }) => {
      onSet((uuid) => {
        if (uuid) {
          setInStorage(LAST_SELECTED_BUSINESS_UUID, uuid);
        }
      });
    },
  ],
});

export const selectedBusinessUuidStateSelector = selector({
  key: 'selectedBusinessUuidStateSelector',
  get: ({ get }) => get(selectedBusinessUuidState),
  set: ({ set, reset }, newValue) => {
    set(selectedBusinessUuidState, newValue);
    reset(bookingFlowRoom);
    reset(bookingFlowDate);
    reset(bookingFlowLocation);
    reset(bookingFlowType);
    reset(categoriesFilterState);
  },
});

export const selectedBusinessState = atom<null | (Business & BusinessWithUser)>({
  key: 'selectedBusinessState',
  default: null,
});

const isPublicImage = (path: string | null) => path?.includes('storage.googleapis.com');

export const businessCourseTypesState = atom<Array<CourseType & SelectOption> | null>({
  key: 'businessCourseTypesState',
  default: null,
  effects: [
    ({ onSet, setSelf }) => {
      onSet(async (items) => {
        if (items) {
          const itemsCopy = [];

          for (const item of items) {
            const itemCopy = { ...item };

            if (itemCopy?.imagePath) {
              if (isPublicImage(itemCopy.imagePath)) {
                itemCopy.image = itemCopy.imagePath;
              } else {
                const imageBlob = await downloadPicture(itemCopy.imagePath);

                itemCopy.image = URL.createObjectURL(imageBlob);
              }
            }

            itemsCopy.push(itemCopy);
          }

          setSelf(itemsCopy as Array<CourseType & SelectOption>);
        }
      });
    },
  ],
});

export const businessLocationsState = atom<Array<Location & SelectOption> | null>({
  key: 'businessLocationsState',
  default: null,
});

export const menuCollapsedState = atom<boolean>({
  key: 'menuCollapsedState',
  default: getFromStorage(MENU_COLLAPSED_STORE) === 'true',
  effects: [
    ({ onSet }) => {
      onSet((collapsed) => {
        setInStorage(MENU_COLLAPSED_STORE, collapsed);
      });
    },
  ],
});

export const reportMenuCollapsedState = atom<boolean>({
  key: 'reportMenuCollapsedState',
  default: getFromStorage(REPORT_MENU_COLLAPSED_STORE) === 'true',
  effects: [
    ({ onSet }) => {
      onSet((collapsed) => {
        setInStorage(REPORT_MENU_COLLAPSED_STORE, collapsed);
      });
    },
  ],
});
