import {
  businessCourseTypesState,
  businessLocationsState,
  selectedBusinessState,
  selectedBusinessUuidStateSelector,
} from '@/business/atoms';
import { getInitials, getTranslatorStringForPlurals } from '@/common/utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { CourseType, Membership, MembershipType, UserPurchasePermission } from '@/web/types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const minutesToFormat = (t: TFunction, minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  const minsString = `${mins} ${t(getTranslatorStringForPlurals('minute', mins !== 1), { count: mins })}`;

  if (hours === 0) {
    return minsString;
  }

  const hoursString = `${hours} ${t(getTranslatorStringForPlurals('hour', hours !== 1), { count: hours })}`;

  if (mins) {
    return `${hoursString} ${t('and')} ${minsString}.`;
  }

  return hoursString;
};

export const useBusiness = () => {
  const { t } = useTranslation();
  const selectedBusiness = useRecoilValue(selectedBusinessState);
  const [businessUuid, setBusinessUuid] = useRecoilState(selectedBusinessUuidStateSelector);
  const businessCTypes = useRecoilValue(businessCourseTypesState);
  const businessLocations = useRecoilValue(businessLocationsState);

  const businessSettings = selectedBusiness?.settings;
  const businessFeatures = selectedBusiness?.features;
  const businessName = selectedBusiness?.name;
  const businessEmail = selectedBusiness?.email;
  const businessPhoneNumber = selectedBusiness?.phoneNumber;
  const businessWebsiteUrl = selectedBusiness?.websiteUrl;
  const businessAddress = selectedBusiness?.address;
  const businessRegistrationNumber = selectedBusiness?.registrationNumber;
  const businessLogo = selectedBusiness?.publicLogoUrl;
  const businessIcon = selectedBusiness?.publicIconUrl;
  const businessUser = selectedBusiness?.businessUser;
  const businessIban = selectedBusiness?.iban;
  const businessInitials = getInitials(businessName || '');
  const primaryLocationId = businessUser?.primaryLocationId;
  const businessLocation = businessLocations?.find((location) => location.id === primaryLocationId);
  const timeframeBeforeCancel = minutesToFormat(t, selectedBusiness?.settings?.cancelCourseMinutes || 0);
  const isBusinessOnboarded = selectedBusiness?.status !== 'ONBOARDING';

  return useMemo(
    () => ({
      businessUuid,
      setBusinessUuid,
      businessFeatures,
      businessName,
      businessEmail,
      businessPhoneNumber,
      businessWebsiteUrl,
      businessAddress,
      businessRegistrationNumber,
      businessInitials,
      businessLocations,
      businessActivitiesMap: (businessCTypes || []).reduce(
        (acc, courseType) => {
          acc[courseType.id] = courseType;
          return acc;
        },
        {} as Record<string, CourseType>,
      ),
      primaryLocationId,
      businessSettings,
      businessLogo,
      businessIcon,
      businessUser,
      businessIban,
      businessCTypes,
      timeframeBeforeCancel,
      isBusinessOnboarded,
      businessLocation,
    }),
    [
      businessCTypes,
      businessIcon,
      businessFeatures,
      businessInitials,
      businessLocations,
      businessLogo,
      businessName,
      businessEmail,
      businessPhoneNumber,
      businessWebsiteUrl,
      businessAddress,
      businessRegistrationNumber,
      businessSettings,
      businessUser,
      businessIban,
      businessUuid,
      primaryLocationId,
      setBusinessUuid,
      timeframeBeforeCancel,
      isBusinessOnboarded,
      businessLocation,
    ],
  );
};
