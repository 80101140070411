import { gaxios } from '@/api';
import { Guest } from '@/features/guest/types/Guest';

export const getGuest = async (businessId: string, eventId: string, guestId: string, token: string): Promise<Guest> => {
  const resp = await gaxios({
    method: 'GET',
    url: `/v1/businesses/${businessId}/activity-events/${eventId}/guests/${guestId}`,
    params: {
      token,
    },
  });

  return resp.data;
};

export const cancelGuestBooking = async (
  businessId: string,
  eventId: string,
  guestId: string,
  token: string,
): Promise<unknown> => {
  const resp = await gaxios({
    method: 'POST',
    url: `/v1/businesses/${businessId}/activity-events/${eventId}/guests/${guestId}/cancel`,
    data: {
      token,
    },
  });

  return resp.data;
};
