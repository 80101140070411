import { GSelect, SelectProps } from '@/design-system/v3/g-select';
import { useBusinessMemberships } from '@/web/hooks/use-business-memberships';
import { GLoader } from '@/design-system/g-loader';

export const MembershipSelect = (props: Omit<SelectProps, 'options'>) => {
  const { memberships, isLoading } = useBusinessMemberships();

  if (isLoading) {
    return <GLoader variant="primary" />;
  }

  return (
    <GSelect
      options={memberships.map((membership) => ({
        label: membership.name,
        value: membership.id,
      }))}
      placeholder="Pick a Membership"
      {...props}
    />
  );
};
