import { popoverModalState } from '@/common/atoms';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

const emptyObject = {};

export const PopoverModals = () => {
  const [modal, setModal] = useRecoilState(popoverModalState);
  const [noPadding, setNoPadding] = useState<boolean>(modal?.props?.noPadding || modal?.noPadding || false);
  const Content = modal?.component;
  const Title = modal?.title;
  const isModalOpen = !!modal?.isOpen;

  useEffect(() => {
    if (modal) {
      setNoPadding(modal?.props?.noPadding || modal?.noPadding || false);
    }
  }, [modal]);

  const handleClose = () => {
    setModal(null);
  };

  const modalProps = {
    handleClose,
    ...(modal?.props ? modal.props : emptyObject),
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog as="div" className="z-modal relative" onClose={modalProps.handleClose}>
        <div className="fixed inset-0 bg-scrim" aria-hidden="true" />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-100 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  modal?.className,
                  'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all',
                  {
                    'p-6': !noPadding,
                  },
                )}
              >
                {Title && (
                  <Dialog.Title as="h3" className="mb-4 text-lg font-medium leading-6 text-gray-900">
                    {Title}
                  </Dialog.Title>
                )}
                {Content && <Content {...modalProps} />}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
