import { BusinessFeatures } from '@/business/types';
import type { DateTime, UUID } from '@/common/types';

export interface SelectOption {
  label: string;
  value: string;
}

export interface CalendarFilters {
  [key: string]: any;
}

export enum Role {
  MEMBER = 'MEMBER',
  STAFF = 'STAFF',
  TEACHER = 'TEACHER',
  POS = 'POS',
  ADMIN = 'ADMIN',
}

export type BusinessType = 'GYM';

export const enum MembershipType {
  USER = 'USER',
  ORGANIZATION = 'ORGANIZATION',
}

export const enum MembershipPaymentType {
  PERIODIC = 'PERIODIC',
  ONCE = 'ONCE',
  YEARLY = 'YEARLY',
}

export const enum MembershipEntriesControl {
  DISABLED = 'DISABLED',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  FIXED_AMOUNT = 'FIXED_AMOUNT',
}

export const enum MembershipAccess {
  Always = 'always',
  Custom = 'custom',
  Daily = 'daily',
}

export interface Location {
  address: null;
  iban: string | null;
  companyRegistrationNumber: string | null;
  businessId: string;
  publicImageUrl: string | null;
  createdAt: string;
  id: string;
  name: string;
  timezone: string;
  updatedAt: string;
}

export interface MembershipTime {
  id: string;
  businessMembershipId: UUID;
  accessDays: number[];
  lowTime: DateTime;
  upperTime: DateTime;
}

export interface MembershipCourseType {
  id: string;
  businessMembershipId: string;
  courseTypeId: string;
}

export const enum MembershipVisibility {
  PUBLIC = 'PUBLIC',
  LOCATION = 'LOCATION',
  PRIVATE = 'PRIVATE',
}

export enum OrganizationDiscountMode {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE',
}

/**
 * The permission for user-initiated purchases.
 */
export enum UserPurchasePermission {
  /**
   * The user is allowed to purchase the membership.
   */
  ALWAYS = 'ALWAYS',

  /**
   * The user can only renew the membership.
   */
  RENEWAL_ONLY = 'RENEWAL_ONLY',

  /**
   * The user can never purchase this membership, it has to be assigned by the business.
   */
  NEVER = 'NEVER',
}

export const enum MembershipLocationAccess {
  ALL = 'ALL',
  PRIMARY_LOCATION = 'PRIMARY_LOCATION',
  SPECIFIC_LOCATIONS = 'SPECIFIC_LOCATIONS',
}

export interface Membership {
  amount: number;
  amountFormatted: string;
  currency: 'EUR';
  id: string;
  name: string;
  description: string | null;
  type: MembershipType;
  paymentType: MembershipPaymentType;
  lowTime: string | null;
  upperTime: string | null;
  timeslots: MembershipTime[];
  expireDays: number | null;
  vatRate: number;
  entries?: number;
  freePass: false;
  public?: boolean;
  visibility: MembershipVisibility;
  visibilityLocations: string[];
  courseTypes: MembershipCourseType[];
  entriesControl: MembershipEntriesControl;
  entriesControlCourseTypes: CourseType[];
  organizationDetails: {
    organizationPaymentType: OrganizationPaymentType;
    discountMode: OrganizationDiscountMode;
    discountAmount: number;
    discountPercentage: number;
    allowedMemberships: string[];
  } | null;
  userBringAFriendGrant: {
    unit: 'MONTH';
    value: number;
  } | null;
  registrationFee: number | null;
  userPurchasePermission: UserPurchasePermission;
  userLocationAccess: MembershipLocationAccess;
  userAccessibleLocations: string[];
}

export enum OrganizationPaymentType {
  ALL = 'ALL',
  PARTIAL = 'PARTIAL',
}

export interface Address {
  city: string;
  country: string;
  createdAt: string;
  id: string;
  postalCode: string;
  street: string;
  updatedAt: string;
}

export interface BusinessUserMembership {
  id: string;
  active: boolean;
  expiresAt: string;
  expired: boolean;
  createdAt: string;
  activeAt: string | null;
  cancelledAt: string | null;
  amount: number;
  fullAmount: number;
  membership: Membership;
  businessUserId: string;
  stampCard: boolean;
  stampId: string | null;
  stampCheckins: string[];
  discount: BusinessDiscount | null;
}

export interface BusinessDiscount {
  id: string;
  name: string;
  code: string;
  variant: string;
  type: string;
  status: unknown;
  amount: {
    percentage: 50;
    fixedAmount: unknown;
  };
  amountFormatted: string;
  accessLevel: unknown;
  dynamicData: unknown;
  membershipData: {
    expireDays: number | null;
  };
}

export interface BusinessUser {
  active: boolean;
  accountId: string;
  address: Address;
  businessId: string;
  createdAt: string;
  customerId: string;
  email: string;
  fullName: string;
  firstName: string;
  insertion: string | null;
  iban: string;
  id: string;
  lastName: string;
  activeMemberships: BusinessUserMembership[];
  futureMemberships: BusinessUserMembership[];
  membershipsAwaitingPayment: BusinessUserMembership[];
  phoneNumber: string;
  pictureId: string | null;
  picturePath: string | null;
  profileImagePath: string | null;
  profileImageId: string | null;
  profileImageUrl: string | null;
  primaryLocationId: string;
  type: Role;
  updatedAt: string;
}

export type BusinessRoles = 'ROLE_USER_ADMIN' | 'ROLE_USER_TEACHER' | 'ROLE_USER_MEMBER';

export enum PaymentProcessingLevel {
  BUSINESS = 'BUSINESS',
  LOCATION = 'LOCATION',
}

export interface Branding {
  primaryColor?: string;
  secondaryColor?: string | null;
}

export enum HomeScreenMode {
  CLASS_SCHEDULE = 'CLASS_SCHEDULE',
  DISCOVERY = 'DISCOVERY',
}

export enum CheckinUsageType {
  ALL = 'ALL',
  NFC_ONLY = 'NFC_ONLY',
}

export interface BusinessSettings {
  cancelCourseMinutes: number;
  paymentProcessingLevel: PaymentProcessingLevel;
  branding?: Branding;
  hasTermsAndConditions: boolean;
  homeScreenMode: HomeScreenMode;
  checkinUsageType: CheckinUsageType;
}

export interface Business {
  active: boolean;
  createdAt: string;
  iconId: string | null;
  iconImagePath: string | null;
  publicImageUrl: string | null;
  logoId: string;
  features: BusinessFeatures;
  settings: BusinessSettings;
  updatedAt: string;
  id: string;
  name: string;
  logoImagePath: string;
  address: Address | null;
  registrationNumber: string | null;
  email: string | null;
  phoneNumber: string | null;
  websiteUrl: string | null;
  publicLogoUrl?: string;
  publicIconUrl?: string;
  iban?: string;
  type: BusinessType;
  status: string;
}

export interface BusinessWithUser {
  businessUser: BusinessUser;
  id: string;
  name: string;
  type: BusinessType;
}

/**
 * The reason for why a chargeback has occurred for a payment.
 */
export enum PaymentChargebackReason {
  /**
   * There were insufficient funds on the customer's account.
   */
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',

  /**
   * A refund has been requested by the customer.
   */
  REFUND_REQUESTED = 'REFUND_REQUESTED',

  /**
   * No reason was specified for the chargeback.
   */
  NO_REASON_SPECIFIED = 'NO_REASON_SPECIFIED',

  /**
   * The reason for the chargeback is unknown.
   */
  UNKNOWN = 'UNKNOWN',
}

export interface BusinessUserSignupResponse {
  businessUser: BusinessUser;
  redirectUrl: string;
}

export interface CourseType {
  businessId: string;
  createdAt: string;
  imagePath: string | null;
  image: string | null;
  id: string;
  name: string;
  description: string | null;
  color: string | null;
  updatedAt: string;
  options: {
    subscribeCourseMinutes: number;
    subscribeDays: number;
    price: number | null;
  };
  parentCourseTypeId: string | null;
}

export interface CourseTeacher {
  id: string;
  firstName: string;
  insertion: string | null;
  lastName: string;
  fullName: string;
  profileImageId: string | null;
  profileImagePath: string | null;
}

export interface CourseGuest {
  businessId: string;
  courseDate: string;
  courseId: string;
  createdAt: string;
  email: string;
  expiresAt: string;
  firstName: string;
  id: string;
  lastName: string;
  locationID: string;
  membership: Membership;
  paymentType: 'AT_THE_BUSINESS';
  status: 'ACTIVE';
  updatedAt: string;
}

export interface CourseMember {
  id: string;
}

export type CourseRecurringType = 'ONCE' | 'DAILY' | 'WEEKLY' | 'MONTHLY';

export interface CourseForm {
  name: string;
  from: string;
  duration: string;
  capacity: number;
  date: Date;
  primaryLocationId: string;
  teacherIds: string[];
  typeIds: string[];
  repeat: CourseRecurringType;
}

export enum ExternalBookingProvider {
  FITOGRAM = 'FITOGRAM',
  CLASS_PASS = 'CLASS_PASS',
}

export enum CourseDetailMemberType {
  MEMBER = 'MEMBER',
  GUEST = 'GUEST',
  EXTERNAL = 'EXTERNAL',
}

export interface CourseDetailMember {
  id: string;
  firstName: string;
  insertion: string | null;
  lastName: string;
  fullName: string | null;
  attended: boolean;
  profileImageUrl: string | null;
  externalProviderId: ExternalBookingProvider | null;
  type: CourseDetailMemberType;
  status: CourseMemberStatus;
  birthday: boolean | null;
}

export type CourseRecurringPattern = {
  daysOfWeek: number[];
  exceptions: string[];
  recurringType: CourseRecurringType;
} | null;

export enum CourseAccessRestriction {
  NO_ACCESS = 'NO_ACCESS',
  NO_LOCATION_ACCESS = 'NO_LOCATION_ACCESS',
  TOO_EARLY = 'TOO_EARLY',
  NO_MEMBER = 'NO_MEMBER',
  NO_ACTIVE_MEMBERSHIP = 'NO_ACTIVE_MEMBERSHIP',
  ACTIVITY_RESTRICTION = 'ACTIVITY_RESTRICTION',
  ACTIVITY_EVENT_ENTRIES_LIMIT_RESTRICTION = 'ACTIVITY_EVENT_ENTRIES_LIMIT_RESTRICTION',
  TIME_SLOT_NOT_ALLOWED = 'TIME_SLOT_NOT_ALLOWED',
  INVOICE_DUE = 'INVOICE_DUE',
}

export interface Course {
  businessLocationId: string;
  capacity: number;
  createdAt: string;
  description: string;
  endAt: string;
  id: string;
  imageId: string | null;
  memberCount: number;
  members: CourseMember[];
  isWaiting: boolean;
  guests: CourseGuest[];
  name: string;
  parentCourseId: null;
  recurring: boolean;
  recurringEndDate: string | null;
  recurringPattern: CourseRecurringPattern;
  startAt: string;
  teachers: CourseTeacher[];
  teacherIds?: string[];
  activities: string[];
  updatedAt: string;
  accessRestrictions: CourseAccessRestriction[];
  utcOffset: number;
}

export interface CourseState {
  [key: string]: CourseWithHelpers;
}

export interface CourseDetail {
  isVisiting: boolean;
  isWaiting: boolean;
  memberCount: number;
  capacity: number;
  members: CourseDetailMember[];
  name: string;
  description: string | null;
  publicImageUrl: string | null;
  recurring: boolean;
  recurringEndDate: string | null;
  recurringPattern: CourseRecurringPattern;
  teachers: CourseTeacher[];
  startAt: string;
  endAt: string;
  imageId: string | null;
  calendarId: string | null;
  price: number | null;
  userPrice: number | null;
  discount: { amountFormatted } | null;
  id: string;
  guests: CourseGuest[];
  courseTypeImageId: string | null;
  activities: string[];
  businessLocation: Location;
  accessRestrictions: CourseAccessRestriction[];
  roomId: string | null;
  isCancelled: boolean;
  utcOffset: number;
}

export interface CourseWithHelpers extends Course {
  uniqueUuid: string;
  detailedActivities: CourseType[];
  startAtDate: Date;
  endAtDate: Date;
  originalStartAt: string;
  originalStartAtDate: Date;
  originalEndAt: string;
  originalEndAtDate: Date;
  isPast: boolean;
}

export interface CourseDetailWithHelpers extends CourseDetail {
  startAtDate: Date;
  detailedActivities: CourseType[];
  uniqueUuid: string;
  endAtDate: Date;
  originalStartAt: string;
  originalStartAtDate: Date;
  originalEndAt: string;
  originalEndAtDate: Date;
  isPast: boolean;
}

export enum CourseMemberStatus {
  ACTIVE = 'ACTIVE',
  WAITING = 'WAITING',
  CANCELLED = 'CANCELLED',
  LATE_CANCELLED = 'LATE_CANCELLED',
  NO_SHOW = 'NO_SHOW',
  ATTENDED = 'ATTENDED',
}

export interface CourseMemberDetails {
  courseDetails: CourseWithHelpers;
  status: CourseMemberStatus;
}

export interface CourseWithOverflow {
  original: CourseWithHelpers;
  overflow: CourseWithHelpers | null;
}

export interface CourseGroup {
  id: string;
  courses: CourseWithHelpers[];
}

export enum InvoiceType {
  SUBSCRIPTION = 'SUBSCRIPTION',
  POS = 'POS',
}

export enum InvoiceStatus {
  PAID = 'PAID',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  RETRY = 'RETRY',
  CHARGEBACK = 'CHARGEBACK',
  CANCELLED = 'CANCELLED',
}

export interface Invoice {
  id: string;
  number: number;
  numberFormatted: string;
  type: InvoiceType;
  businessId: string;
  businessLocationId: string;
  businessUser: BusinessUser;
  totalAmount: number;
  status: InvoiceStatus;
  createdAt: string;
}

export interface GymlyInvoice extends Invoice {
  id: string;
  number: number;
  numberFormatted: string;
  totalAmount: number;
  status: InvoiceStatus;
  createdAt: string;
}

export interface RevenueStatistics {
  totalRevenue: number;
  totalVat: number;
  revenuePerType: {
    [key in InvoiceType]: number;
  };
  vatPerType: {
    [key in InvoiceType]: number;
  };
  totalPerVat: {
    [key: number]: number;
  };
  dailyRevenue: {
    date: string;
    revenue: number;
  }[];
}

export interface PosStatistics {
  /**
   * The daily revenue.
   */
  dailyRevenue: {
    date: string;
    revenue: number;
  }[];
  /**
   * The revenue per category where the key is the UUID of the category
   * and the value is the amount of revenue in euro's.
   */
  revenuePerCategory: { [key: string]: number };
  /**
   * The VAT per category where the key is the UUID of the category.
   * The value is an object where the key is the VAT percentage and the value.
   */
  vatPerCategory: { [key: string]: { [key: string]: number } };
  /**
   * The amount of sales per payment method.
   */
  salesPerPaymentMethod: { [key: string]: number };
  /**
   * The amount of orders placed by day and time.
   */
  salesPerDayTime: { day: number; time: string; sales: number }[];

  /**
   * The amount of sales per product.
   */
  salesPerProduct: { id: string; name: string; sales: number }[];

  /**
   * The amount of sales per employee.
   */
  salesPerEmployee: { employee: BusinessUser; sales: number }[];
}

export interface CourseTypeActivityStatistics {
  [key: string]: {
    dayOfWeek: number;
    time: string;
    members: number[];
    attended: number[];
    noShows: number[];
    waitlist: number[];
    capacity: number;
  }[];
}

export interface TrainingFrequencyDistributionStatistics {
  [key: string]: {
    frequency: number;
    amount: number;
  }[];
}

export interface CourseTypeMemberStatistics {
  members: {
    businessUser: BusinessUser;
    visits: number;
  }[];
}

export interface UniqueMembersPerCourseTypeStatistics {
  [key: string]: number;
}

export interface NewMembersStatistics {
  newUsersPerDay: {
    date: string;
    amount: number;
  }[];
}

export interface NewSubscriptionsStatistics {
  subscriptionsPerDay: {
    date: string;
    totalCount: number;
    countPerMembership: { [key: string]: number };
  }[];
}

export interface CancelledSubscriptionsStatistics {
  subscriptionsPerDay: {
    date: string;
    totalCount: number;
    countPerMembership: { [key: string]: number };
  }[];
}

export interface ActiveSubscriptionsStatistics {
  subscriptionsPerWeek: {
    membershipId: string;
    weekStart: string;
    weekEnd: string;
    weekNumber: number;
    activeSubscriptions: number;
  }[];
}

export interface CheckinStatistics {
  amountPerDayTime: {
    dayOfWeek: number;
    time: string;
    amount: number;
  }[];
}

export interface ReservationsStatistics {
  reservations: {
    dayOfWeek: number;
    time: string;
    amount: number;
  }[];
}

export interface StoredEvent {
  id: string;
  type: string;
  payload: { [key: string]: any };
  occurredOn: string;
}

export interface StoredEventResponse {
  events: StoredEvent[];
  continuationToken: string | null;
}

export interface EventNote {
  event: StoredEvent;
  businessUser: BusinessUser;
  creator: BusinessUser;
  content: string;
  createdAt: string;
}

export enum KycStatus {
  'NOT_STARTED' = 'NOT_STARTED',
  'PENDING' = 'PENDING',
  'COMPLETED' = 'COMPLETED',
}

export interface PaymentSchedule {
  type: 'MONTHLY' | 'WEEKLY';
  weekInterval: number | null;
  dayOfMonth: number | null;
}

export interface CourseDetailForm {
  name: string;
  from: string;
  duration: string;
  capacity: number;
  date: Date;
  primaryLocationId: string;
  teacherIds: string[];
  activityIds: string[];
  repeat: CourseRecurringType;
}

export enum UserDataSetting {
  LAST_NAME_REQUIRED = 'lastNameRequired',
  DATE_OF_BIRTH_REQUIRED = 'dateOfBirthRequired',
  ADDRESS_REQUIRED = 'addressRequired',
  GENDER_REQUIRED = 'genderRequired',
  PHONE_NUMBER_REQUIRED = 'phoneNumberRequired',
}

export type UserDataSettings = {
  [key in Role]: {
    lastNameRequired: boolean;
    dateOfBirthRequired: boolean;
    addressRequired: boolean;
    genderRequired: boolean;
  };
};

export enum PaymentStatus {
  /**
   * The payment is pending to be paid.
   */
  PENDING = 'PENDING',

  /**
   * The payment has been paid successfully.
   */
  SUCCESS = 'SUCCESS',

  /**
   * The payment has failed.
   */
  FAILED = 'FAILED',
}

export enum UrlTarget {
  APP = 'APP',
  WEB = 'WEB',
}

export interface AddressDto {
  street: string;
  city: string;
  postalCode: string;
  country: string;
}

export enum RoomLocation {
  LOCATION = 'LOCATION',
  OFFSIDE = 'OFFSIDE',
  ONLINE = 'ONLINE',
}

export interface Room {
  id: string;
  name: string;
  capacity: number;
  location: RoomLocation;
  businessLocationId: string | null;
  courseTypes: string[];
  offsideAddress: AddressDto | null;
  onlineUrl: string | null;
  imageUrl: string | null;
}

export enum CheckinFailureReasonType {
  /**
   * The user does not have a valid membership.
   */
  INVALID_MEMBERSHIP = 'INVALID_MEMBERSHIP',

  /**
   * The user does not have access to this location.
   */
  LOCATION_ACCESS_DENIED = 'LOCATION_ACCESS_DENIED',

  /**
   * The user is unable to check in at this time slot.
   */
  INVALID_TIME_RANGE = 'INVALID_TIME_RANGE',

  /**
   * There is an invoice due.
   */
  INVOICE_DUE = 'INVOICE_DUE',

  /**
   * The user needs to subscribe to a course, but has not done so.
   */
  NO_COURSE_SUBSCRIPTION = 'NO_COURSE_SUBSCRIPTION',

  /**
   * There is a time-out between checkins.
   */
  TIME_OUT = 'TIME_OUT',
}

interface CheckinFailureReason {
  type: CheckinFailureReasonType;
}

export interface InvalidTimeRangeCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.INVALID_TIME_RANGE;
  data: {
    validSlots: {
      id: string;
      accessDays: number[];
      lowTime: DateTime;
      upperTime: DateTime;
    }[];
  };
}

export interface NoCourseSubscriptionCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.NO_COURSE_SUBSCRIPTION;
  data: {
    upcomingEvent: Course | null;
    checkinTime: string | null;
  };
}

export interface InvalidMembershipCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.INVALID_MEMBERSHIP;
}

export interface LocationCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.LOCATION_ACCESS_DENIED;
}

export interface InvoiceDueCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.INVOICE_DUE;
}

export interface TimeOutCheckinFailureReason extends CheckinFailureReason {
  type: CheckinFailureReasonType.TIME_OUT;
  data: {
    expiresAt: string;
  };
}

export type CheckinFailureReasons =
  | InvalidTimeRangeCheckinFailureReason
  | NoCourseSubscriptionCheckinFailureReason
  | InvalidMembershipCheckinFailureReason
  | LocationCheckinFailureReason
  | InvoiceDueCheckinFailureReason
  | TimeOutCheckinFailureReason;

export interface TermsAndConditions {
  id: string;
  version: number;
  content: string;
}

export interface PrivacyPolicy {
  id: string;
  version: number;
  content: string;
}

export interface PaymentSession {
  paymentLink: string;
  paymentStatusToken: string;
}

export interface CustomAppConfiguration {
  appleAppStoreUrl: string;
  googlePlayStoreUrl: string;
}
