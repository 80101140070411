import { FETCH_BUSINESS_MEMBERSHIPS, getBusinessMembershipTypes } from '@/business/endpoints';
import { useBusiness } from '@/web/hooks/use-business';
import { useAuth } from '@/auth/hooks/use-auth';
import { useQuery } from 'react-query';
import { Membership, MembershipType, SelectOption, UserPurchasePermission } from '@/web/types';
import { useMemo } from 'react';

export const useBusinessMemberships = (view: 'onboarding' | null = null) => {
  const { isLoggedIn } = useAuth();
  const { businessUuid } = useBusiness();

  const { data, isLoading } = useQuery([FETCH_BUSINESS_MEMBERSHIPS, businessUuid, isLoggedIn, view], async () => {
    if (!businessUuid) {
      return [];
    }

    return (await getBusinessMembershipTypes(businessUuid, view)).map((membership) => ({
      ...membership,
      label: `${membership.name} (${membership.amountFormatted})`,
      value: membership.id,
    }));
  });

  return useMemo<{
    isLoading: boolean;
    memberships: (Membership & SelectOption)[];
    map: Record<string, Membership & SelectOption>;
    hasPurchasableMemberships: boolean;
  }>(
    () => ({
      isLoading,
      memberships: data ?? [],
      map: (data ?? []).reduce(
        (acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        },
        {} as Record<string, Membership & SelectOption>,
      ),
      hasPurchasableMemberships:
        (data ?? []).filter((membership) => {
          return (
            membership.type === MembershipType.USER &&
            membership.userPurchasePermission !== UserPurchasePermission.NEVER &&
            membership.amount !== 0
          );
        }).length > 0,
    }),
    [data, isLoading],
  );
};
